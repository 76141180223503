import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import { t } from "../../common/LocalizationProvider";

const ForgotPassword = () => {

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const history = useHistory();

    const handleSubmit = async (event) => {
        event.preventDefault();

        const response = await fetch('/api/session/forgetpassowrd', {
            method: 'POST',
            body: new URLSearchParams(`email=${email}`)
        });
        if (response.ok) {
            history.push('/checkEmailMessage')
        }else if (response.statusText === "Not Found")
            setMessage(t("NotFound"));
        else
            setMessage(t("errorMessage"))
    }

    return (
        <>
            <div className="container">
                <h1>{t("findAccount")}</h1>
                <form onSubmit={handleSubmit}>
                    <p>{t("searchForAccount")}</p>
                    <label>
                        <input
                            type="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            placeholder= {t("enterYourEmail")}
                        />
                    </label>
                    <button type="submit">{t("sharedSearch")}</button>
                </form>
                <span style={{ color: 'red' }}>{message}</span>
            </div>

            <style>
                {`
    .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center; /* Center-align the content */
}

label {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

input[type="email"] {
  width: 100%; /* Adjust the width as needed */
  padding: 12px;
  margin-bottom: 10px;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  outline: none;
}

button[type="submit"] {
  width: 100%; /* Set the same width as the input */
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: blue;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

button[type="submit"]:hover {
background-color: green;
}
  `}
            </style>

        </>
    );
}

export default ForgotPassword;
