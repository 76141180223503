import 'typeface-roboto'
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom'
import { Provider } from 'react-redux';
// import { BrowserRouter } from 'react-router-dom'

import App from './App';
// import * as serviceWorker from './serviceWorker';
import store from './store';
import { LocalizationProvider } from "./common/LocalizationProvider";

ReactDOM.render((
    <Provider store={store}>
        {/*<LocalizationProvider>*/}
        <HashRouter>
            <App />
        </HashRouter>
        {/*</LocalizationProvider>*/}
    </Provider>
), document.getElementById('root'));

// serviceWorker.register();
