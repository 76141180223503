import { t } from '../common/LocalizationProvider'
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { formatPosition } from '../common/formatter';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListAltIcon from "@material-ui/icons/ListAlt";
import Tooltip from '@material-ui/core/Tooltip';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';

import LocationOnIcon from "@material-ui/icons/LocationOn";
import ReplayIcon from "@material-ui/icons/Replay";
import { useSelector } from 'react-redux';

import { map } from './Map';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import RemoveDialog from "../RemoveDialog";
import ActionDialog from "../ActionDialog";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
}));

const getStatusColor = (status) => {
    switch (status) {
        case 'online':
            return 'green';
        case 'offline':
            return 'gray';
        case 'slowing':
            return 'yellow';
        default:
            return 'red';
    }
};

const StatusView = ({ history , deviceId, onShowDetails }) => {
    const device = useSelector(state => state.devices.items[deviceId]);
    const position = useSelector(state => state.positions.items[deviceId]);
    const [selectedAnchorEl, setSelectedAnchorEl] = useState(null);
    const [removeDialogShown, setRemoveDialogShown] = useState(false);
    const [actionDialogShown, setActionDialogShown] = useState(false);
    const [tooltipDetail, showTooltipDetail] = useState(false);
    const [tooltipPlay, showTooltipPlay] = useState(false);

    const classes = useStyles();

    const onMenuClick = (anchorId) => {
        setSelectedAnchorEl(anchorId);
    }
    const menuHide = () => {
        setSelectedAnchorEl(null);
    }
    const handleEdit = () => {
        history.push(`device/${deviceId}`);
        menuHide();
    }
    const handleRemove = () => {
        setRemoveDialogShown(true);
        menuHide();
    }
    const handleAction = () => {
        setActionDialogShown(true)
        menuHide();
    }
    const handleRemoveResult = () => {
        setRemoveDialogShown(false);
    }
    const handleClose = () => {
        setActionDialogShown(false)
    }
    const handleClick = e => {
        showTooltipDetail(false)
        e.preventDefault();
        onShowDetails(position.id);
    };
    const onZoom = (e) => {
        if(map.getZoom() < 8  )
            map.flyTo({
                center: [position.longitude , position.latitude],
                zoom: [15] });
        else
            map.flyTo({ zoom: [1.9] });
    }

    const signalStrength = position.attributes.gpsSgn;

    const color = (signal) => {
            if (signal<3)
                //red
                return '#C20000';
            else if (signal>=3 && signal<5)
                //yellow
                return '#FFD000'
            else
                //green
                return '#00FF00'
    }

    const handeleReplay = () => {
        showTooltipPlay(false)
        history.push(`replay/${device.id}`)
    }

    return (
        <>
        {device &&
        <Card className={classes.root}>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe" style={{"backgroundColor": getStatusColor(device.status)}}>
                        <img className={classes.icon} src={`images/icon/${device.category || 'default'}.svg`} alt=""/>
                    </Avatar>
                }
                title={device.name}
                subheader={device.uniqueId}
                style={{"backgroundColor": getStatusColor(device.status)}}
                action={
                        <SignalCellularAltIcon
                            style={{
                                fontSize: 16,
                                verticalAlign: 'middle',
                                color: color(signalStrength),
                                transform: 'scale(2)',
                                marginTop: -2,
                            }}
                        />
                }
            />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    <b>{t('deviceStatus')}:</b> {t(formatPosition(device.status, 'status').toLowerCase())}<br/>
                    <b>{t('sharedLocation')}:</b> {formatPosition(position, 'latitude')} {formatPosition(position, 'longitude')}<br/>
                    <b>{t('Lastpositioning')}:</b> {formatPosition(position.fixTime, 'fixTime')}<br/>
                    <b>{t('positionSpeed')}:</b> {formatPosition(position.speed, 'speed')}<br/>
                    <b>{t('obd2Status')}:</b> {t(formatPosition(position.attributes.obd2Status, 'obd2Status')+'OBDII')}<br/>
                    {position.attributes.obd2Status === 'ON' &&
                    <><b>{t('rpm')}:</b> {formatPosition(position.attributes.rpm, 'rpm')}<br/>
                    </>
                    }
                    {position.attributes.tanklevelSensor === 'OFF' ?
                            null
                        :
                        <>
                            <b>{t('positionTanklevel')}:</b> {formatPosition(position.attributes.tanklevel, 'tanklevel')}<br/>
                        </>
                    }
                </Typography>
            </CardContent>

            <CardActions disableSpacing>
                <Tooltip title={t("detail")} open={tooltipDetail}>
                    <IconButton
                        onClick={handleClick}
                        onMouseEnter={() => showTooltipDetail(true)}
                        onMouseLeave={() => showTooltipDetail(false)}
                        aria-label={t("detail")}>
                        <ListAltIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Zoom">
                    <IconButton
                        onClick={onZoom}
                        aria-label={t("tracking")}>
                        <LocationOnIcon/>
                    </IconButton>
                </Tooltip>

                <Tooltip title={t("playback")} open={tooltipPlay}>
                    <IconButton
                        onClick={handeleReplay}
                        onMouseEnter={() => showTooltipPlay(true)}
                        onMouseLeave={() => showTooltipPlay(false)}
                        aria-label={t("playback")}>
                        <ReplayIcon/>
                    </IconButton>
                </Tooltip>

                <Tooltip title={t("actions")}>
                    <IconButton onClick={(event) => onMenuClick(event.currentTarget)}>
                        <MoreVertIcon/>
                    </IconButton>
                </Tooltip>

                <Menu open={!!selectedAnchorEl} anchorEl={selectedAnchorEl} onClose={menuHide}>
                    <MenuItem onClick={handleEdit}>{t('sharedEdit')}</MenuItem>
                    <MenuItem onClick={handleRemove}>{t('sharedRemove')}</MenuItem>
                    <MenuItem onClick={handleAction}>{t('moreactions')}</MenuItem>
                </Menu>

                <RemoveDialog open={removeDialogShown} endpoint={"devices"} itemId={deviceId} onResult={handleRemoveResult}/>
                <ActionDialog open={actionDialogShown} itemId={deviceId} handleClose={handleClose} history={history}/>

            </CardActions>
        </Card>
        }
        </>
    );
};

export default StatusView;
