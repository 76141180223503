import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { t } from './common/LocalizationProvider';
import EditItemView from './EditItemView';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    makeStyles,
    Typography,
    FormControlLabel,
    Checkbox, Snackbar,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditAttributesView from './attributes/EditAttributesView';
import deviceAttributes from './attributes/deviceAttributes';
import SelectField from './form/SelectField';
import { deviceCategories } from './common/deviceCategories';
import LinkField from './form/LinkField';
import { prefixString } from './common/stringUtils';
import {useSelector} from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DialogActions from "@material-ui/core/DialogActions";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
    details: {
    flexDirection: 'column',
  },
    configue: {
    color: 'red'
  },
    buttons: {
        display: 'flex',
        justifyContent: 'space-evenly',
        '& > *': {
            flexBasis: '33%',
        },
    },
    loading: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

const DevicePage = () => {

    const classes = useStyles();
    const user = useSelector(state => state.session.user && state.session.user);
    const storedToken = localStorage.getItem('tokenThingwings');
    const history = useHistory();

    const [item, setItem] = useState();
    const [open, setOpen] = useState(false);
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [msg, setmsg] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setItem((pervItem) => ({...pervItem, phone: user.phone}))
    },[user.phone])

    const handleOpen = () => {
        setOpen(true)
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    function handleDisable() {
        //if (loading)
        return false;
        //else
        //return false;
    }

    const handleEdit = async (itemId) => {
        setLoading(true)
        let url = `/api/devices`;
        url += `/${itemId}`;
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': 'Bearer ' + storedToken,
                },
                body: JSON.stringify(item),
            });
            if (response)
                setLoading(false)
            if (response.ok)
                history.goBack();
            else if (response.status === 400){
                const errorText = await response.text();
                let spliteText = errorText.split(' ')[0];
                setmsg(t(spliteText))
                setOpenSnackbar(true)
            }
            else {
                setOpenSnackbar(true)
                setmsg(t('UnknownProblem'))
            }
        } catch (error) {
            console.error('Fetch error:', error.message);
            throw error;
        }
    };

    const handleRemove = async (itemId) => {
        setLoading(true)
        const response = await fetch(`/api/devices/${itemId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': 'Bearer ' + storedToken,
                'password': password
            }
        });
        if (response)
            setLoading(false)
        if (response.ok)
            history.goBack();
        else if (response.status === 400){
            const errorText = await response.text();
            let spliteText = errorText.split(' ')[0];
            setmsg(t(spliteText))
            setOpenSnackbar(true)
        }
        else {
            setmsg(t('UnknownProblem'))
            setOpenSnackbar(true)
        }
    };

    return (
        <EditItemView endpoint="devices" item={item} setItem={setItem}>
            {item &&
            <>
                {(user.subadmin && item.id && (item.configured === "should_delete_C" || item.configured === "should_delete_N")) ? (
                    <>
                        <Accordion defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="subtitle1" style={{ fontSize: '1.2rem'}}>
                                    {t('confirmation')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.details} >
                                {user.subadmin  ? (<>
                                    {t('deleteion_Confirmation')} {item.name}
                                </>) : (<>
                                    {t('should_delete_C')}
                                </>)}

                            </AccordionDetails>
                        </Accordion>
                        <div>
                            {loading && <div className={classes.loading}>
                                <CircularProgress color="secondary" />
                            </div>}
                        </div>
                        <FormControl fullWidth margin="normal">
                            <div className={classes.buttons}>
                                <Button
                                    type="button"
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => handleEdit(item.id)}
                                    disabled={handleDisable()}
                                >
                                    {t('refuse')}
                                </Button>
                                <Button
                                    type="button"
                                    color="primary"
                                    variant="contained"
                                    onClick={handleOpen}
                                    disabled={handleDisable()}
                                >
                                    {t('Confirm')}
                                </Button>
                            </div>
                        </FormControl>
                        <Dialog
                            open={open}
                            onClose={() => { setOpen(false)}}
                            >
                            <DialogContent>
                                <DialogContentText >{t('sharedRemoveBecareful')}</DialogContentText>
                            </DialogContent>
                        <TextField
                            margin="normal"
                            value={password || ''}
                            onChange={(e) => setPassword(e.target.value)}
                            label={t("userPassword")}
                            variant="filled"
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                            <DialogActions>
                                <Button color="primary" onClick={() => handleRemove(item.id)} style={{color: 'red'}}>{t('sharedRemove')}</Button>
                                {/*<Button autoFocus onClick={() => onResult(false)}>{t('sharedCancel')}</Button>*/}
                                <Button autoFocus onClick={() => setOpen(false)}>{t('sharedCancel')}</Button>
                            </DialogActions>
                        </Dialog>
                        <Snackbar open={openSnackbar} autoHideDuration={12000} onClose={handleCloseSnackbar} >
                            <Alert onClose={handleCloseSnackbar} variant="filled" severity="error">
                                {msg}
                            </Alert>
                        </Snackbar>
                    </>
                ) : (
                    <>
                        <Accordion defaultExpanded>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                <Typography variant="subtitle1">
                                    {t('sharedRequired')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className={classes.details}>
                                {item.id && item.configured !== "configured" &&
                                <div className={classes.configue}>{t(item.configured)}</div>}
                                <TextField
                                    margin="normal"
                                    value={item.name || ''}
                                    onChange={event => setItem({...item, name: event.target.value})}
                                    label={t('sharedName')}
                                    variant="filled"
                                    required/>
                                <TextField
                                    margin="normal"
                                    value={item.uniqueId || ''}
                                    onChange={event => setItem({...item, uniqueId: event.target.value})}
                                    label={t('deviceIdentifier')}
                                    variant="filled"
                                    required/>
                                <TextField
                                    margin="normal"
                                    value={item.phone || (user.phone & user.phone)}
                                    onChange={event => setItem({...item, phone: event.target.value})}
                                    label={t('sharedPhone')}
                                    variant="filled"
                                    required/>
                            </AccordionDetails>
                        </Accordion>
                        {item.configured === "configured" && (
                            <>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography variant="subtitle1">
                                            {t('sharedExtra')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.details}>
                                        <SelectField
                                            margin="normal"
                                            value={item.groupId || 0}
                                            onChange={event => setItem({...item, groupId: Number(event.target.value)})}
                                            endpoint="/api/groups"
                                            label={t('groupParent')}
                                            variant="filled"/>
                                        <TextField
                                            margin="normal"
                                            value={item.model || ''}
                                            onChange={event => setItem({...item, model: event.target.value})}
                                            label={t('deviceModel')}
                                            variant="filled"/>
                                        <TextField
                                            margin="normal"
                                            value={item.contact || ''}
                                            onChange={event => setItem({...item, contact: event.target.value})}
                                            label={t('deviceContact')}
                                            variant="filled"/>
                                        <SelectField
                                            margin="normal"
                                            value={item.category || 'default'}
                                            emptyValue={null}
                                            onChange={event => setItem({...item, category: event.target.value})}
                                            data={deviceCategories.map(category => ({
                                                id: category,
                                                name: t(`category${category.replace(/^\w/, c => c.toUpperCase())}`)
                                            }))}
                                            label={t('deviceCategory')}
                                            variant="filled"/>
                                        {user.subadmin && <FormControlLabel
                                            control={<Checkbox checked={item.disabled}
                                                               onChange={event => setItem({
                                                                   ...item,
                                                                   disabled: event.target.checked
                                                               })}/>}
                                            label={t('sharedDisabled')}/>}
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography variant="subtitle1">
                                            {t('sharedAttributes')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.details}>
                                        <EditAttributesView
                                            attributes={item.attributes}
                                            setAttributes={attributes => setItem({...item, attributes})}
                                            definitions={deviceAttributes}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                                {item.id &&
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography variant="subtitle1">
                                            {t('sharedConnections')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.details}>
                                        <LinkField
                                            margin="normal"
                                            endpointAll="/api/geofences"
                                            endpointLinked={"/api/geofences?deviceId=" + item.id}
                                            baseId={item.id}
                                            keyBase="deviceId"
                                            keyLink="geofenceId"
                                            label={t('sharedGeofences')}
                                            variant="filled"/>
                                        <LinkField
                                            margin="normal"
                                            endpointAll="/api/notifications"
                                            endpointLinked={"/api/notifications?deviceId=" + item.id}
                                            baseId={item.id}
                                            keyBase="deviceId"
                                            keyLink="notificationId"
                                            titleGetter={it => t(prefixString('event', it.type))}
                                            label={t('sharedNotifications')}
                                            variant="filled"/>
                                        <LinkField
                                            margin="normal"
                                            endpointAll="/api/drivers"
                                            endpointLinked={"/api/drivers?deviceId=" + item.id}
                                            baseId={item.id}
                                            keyBase="deviceId"
                                            keyLink="driverId"
                                            label={t('sharedDrivers')}
                                            variant="filled"/>
                                        <LinkField
                                            margin="normal"
                                            endpointAll="/api/attributes/computed"
                                            endpointLinked={"/api/attributes/computed?deviceId=" + item.id}
                                            baseId={item.id}
                                            keyBase="deviceId"
                                            keyLink="attributeId"
                                            titleGetter={it => it.description}
                                            label={t('sharedComputedAttributes')}
                                            variant="filled"/>
                                        <LinkField
                                            margin="normal"
                                            endpointAll="/api/maintenance"
                                            endpointLinked={"/api/maintenance?deviceId=" + item.id}
                                            baseId={item.id}
                                            keyBase="deviceId"
                                            keyLink="maintenanceId"
                                            label={t('sharedMaintenance')}
                                            variant="filled"/>
                                    </AccordionDetails>
                                </Accordion>
                                }
                            </>
                        )}
                    </>
                )}
            </>
            }
        </EditItemView>
    );
}

export default DevicePage;
