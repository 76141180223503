import mapboxgl from 'mapbox-gl';
import { useEffect } from 'react';
import { map } from './Map';

// const ReplayPathMap = ({ positions, subPositions, positionsDashed }) => {
  const ReplayPathMap = ({ positions, subPositions }) => {
  const id = 'replay';
  const idOfPartPath = 'replayOfPartPath';

  useEffect(() => {
    map.addSource(id, {
      'type': 'geojson',
      'data': {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [],
        },
      },
    });
    map.addLayer({
      'source': id,
      'id': id,
      'type': 'line',
      'layout': {
        'line-join': 'round',
        'line-cap': 'round',
      },
      'paint': {
        'line-color': '#C5C8F7',
        'line-width': 5,
      },
    });

    return () => {
      map.removeLayer(id);
      map.removeSource(id);
    };
  }, []);

  useEffect(() => {
    const coordinates = positions.map(item => [item.longitude, item.latitude]);
    map.getSource(id).setData({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: coordinates,
      },
    });
    if (coordinates.length) {
      const bounds = coordinates.reduce((bounds, item) => bounds.extend(item), new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
      map.fitBounds(bounds, {
        // center: [positions[0].longitude, positions[0].latitude],
        // zoom: [10],
        padding: { top: 5, bottom: 295, left: 25, right: 25 },
      });
    }
  }, [positions]);



  useEffect(() => {
    map.addSource(idOfPartPath, {
      'type': 'geojson',
      'data': {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [],
        },
      },
    });
    map.addLayer({
      'source': idOfPartPath,
      'id': idOfPartPath,
      'type': 'line',
      'layout': {
        'line-join': 'round',
        'line-cap': 'round',
      },
      'paint': {
        'line-color': '#000fff',
        'line-width': 5,
      },
    });

    return () => {
      map.removeLayer(idOfPartPath);
      map.removeSource(idOfPartPath);
    };
  }, []);

  useEffect(() => {
    const coordinates = subPositions.map(item => [item.longitude, item.latitude]);
    map.getSource(idOfPartPath).setData({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: coordinates,
      },
    });
  }, [subPositions]);


//this code for draw uncontinu line to simulate the avoiding positions
  // const idDashed = 'replayDashed';
  //
  // useEffect(() => {
  //   console.log("--------------------")
  //   map.addSource(idDashed, {
  //     'type': 'geojson',
  //     'data': {
  //       type: 'Feature',
  //       geometry: {
  //         type: 'LineString',
  //         coordinates: [],
  //       },
  //     },
  //   });
  //   map.addLayer({
  //     'source': idDashed,
  //     'id': idDashed,
  //     'type': 'line',
  //     'layout': {
  //       'line-join': 'round',
  //       'line-cap': 'round',
  //     },
  //     'paint': {
  //       'line-color': '#CFF000',
  //       'line-width': 5,
  //       'line-dasharray': [4, 2],
  //     },
  //   });
  //
  //   return () => {
  //     map.removeLayer(idDashed);
  //     map.removeSource(idDashed);
  //   };
  // }, []);
  //
  // useEffect(() => {
  //     const coordinates = positionsDashed.map(item => [item.longitude, item.latitude]);
  //     map.getSource(idDashed).setData({
  //       type: 'Feature',
  //       geometry: {
  //         type: 'LineString',
  //         coordinates: coordinates,
  //       },
  //     });
  // }, [positionsDashed]);

  return null;
}

export default ReplayPathMap;
