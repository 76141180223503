import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {formatHours, formatPosition} from "../common/formatter";
import { t } from '../common/LocalizationProvider';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flex: 'column',
        fontSize: "0.6rem",
    },
    title: {
        fontWeight: "bold",
    },
    result: {
        fontWeight: "lighter",
    },
    deviceName : {
        fontSize: "0.7rem",
        fontWeight: "bold",
        justifyContent: "center",
        alignItems: "center",
    },
}));

const StartsStopsView = React.memo(({ position, status, deviceName }) => {

    const classes = useStyles();
    const [address, setAddress] = useState(null);

    useEffect(() => {
         fetch(`https://nominatim.openstreetmap.org/reverse?lat=${position.latitude}&lon=${position.longitude}&format=json`, {
            headers: {
                'User-Agent': 'ID of your APP/service/website/etc. v0.1'
            }
        })
        .then(res => res.json())
        .then(res => {
            // console.log(res.address)
            setAddress(res.display_name);
        })
    },[])

    return (
        <div className={classes.root}>
            {position.deviceName ?
                <div>
                    <b className={classes.deviceName}> {position.deviceName} </b> <br/>
                    <b className={classes.title}>{t('Start')}</b> <b className={classes.result}> {formatPosition(position.startTime, 'fixTime')} </b> <br/>
                    <b className={classes.title}>{t('End')}</b> <b className={classes.result}>  {formatPosition(position.endTime, 'fixTime')} </b> <br/>
                    <b className={classes.title}>{t('reportDuration')}</b> <b className={classes.result}>  {formatHours(position.duration)} </b> <br/>
                    <b className={classes.title}>{t('location')}</b> <b className={classes.result}>  {address} </b> <br/>
                </div>
                :
                <div>
                    <b className={classes.deviceName}> {deviceName} </b> <br/>
                    <b className={classes.title}>{t(status)}</b> <b className={classes.result}>  {formatPosition(position.deviceTime, 'fixTime')} </b> <br/>
                    <b className={classes.title}>{t('location')}</b> <b className={classes.result}>  {formatPosition(position.latitude, 'latitude')} {formatPosition(position.longitude, 'longitude')} </b> <br/>
                    <b className={classes.title}>{t('location')}</b> <b className={classes.result}>  {address} </b> <br/>
                </div>
            }
        </div>
    );
});

export default StartsStopsView;