import React, { useState } from 'react';
import { t } from '../common/LocalizationProvider';
import {
  Button,
  Checkbox,
  FilledInput,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles, MenuItem,
  Select
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import AddAttributeDialog from './AddAttributeDialog';

const useStyles = makeStyles(theme => ({
  addButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  removeButton: {
    marginRight: theme.spacing(1.5),
  },
}));

const EditAttributesView = ({ attributes, setAttributes, definitions }) => {
  const classes = useStyles();

  const [addDialogShown, setAddDialogShown] = useState(false);

  const convertToList = (attributes) => {
    let booleanList = [];
    let categoryList = [];
    let otherList = [];
    for (const key in attributes) {
      const value = attributes[key];
      const type = getAttributeType(value);
      if (type === 'category') {
        categoryList.push({ key, value, type });
      }
      else if (type === 'boolean') {
        booleanList.push({ key, value, type });
      } else {
        otherList.push({ key, value, type });
      }
    }
    return otherList.concat(booleanList).concat(categoryList);
  }

  const handleAddResult = (definition) => {
    setAddDialogShown(false);
    if (definition) {
      switch(definition.type) {
        case 'number':
          updateAttribute(definition.key, 0);
          break;
        case 'boolean':
          updateAttribute(definition.key, false);
          break;
        case 'category':
          updateAttribute(definition.key, '');
          break;

        default:
          updateAttribute(definition.key, "");
          break;
      }
    }
  }

  const updateAttribute = (key, value) => {
    let updatedAttributes = {...attributes} ;
    updatedAttributes[key] = value;

    if (key === "EFCategory")
    for (const key1 in definitions) {
      if (key1 !== 'speedLimit' && key1 !== 'report.ignoreOdometer' && key1 !== 'EFCategory' ) {
        if (Object.keys(attributes).length === 0 || attributes[key1] === undefined )
        updatedAttributes[key1] = t('');
      }
    }
    setAttributes(updatedAttributes);
  };

  const deleteAttribute = (key) => {
    let updatedAttributes = {...attributes};
    delete updatedAttributes[key];
    setAttributes(updatedAttributes);
  };

  const getAttributeName = (key) => {
    const definition = definitions[key];
    return definition ? definition.name : key;
  };

  const getAttributeType = (value) => {
    if (typeof value === 'number') {
      return 'number';
    } else if (typeof value === 'boolean') {
      return 'boolean';
    } else {
      return 'string';
    }
  };

  const unitOptions = () => {
    switch(attributes.EFCategory) {
      case 'All':
        // return [t('Weight'), t('Volume'), t('distance')]
        return [t('distance')]
      default:
        return [t('distance')];
    }
  }
  const categoryOptions = [
    t('All'),
    t('Dray'),
    t('Expedited'),
    t('Flatbed'),
    t('HeavyBulk'),
    t('LTLDryVans'),
    t('Mixed'),
    t('Refrigerated'),
    t('Tanker'),
    t('Truck-loadDryVans')
  ]
  const regionOptions = [
    t('australia'),
    t('brazil'),
    t('canada'),
    t('china'),
    t('eu-28'),
    t('india'),
    t('japan'),
    t('mexico'),
    t('russia'),
    t('southkorea'),
    t('unitedstates'),
    t('africa'),
    t('middleeast'),
    t('non-eueurope'),
    t('otherasia-pacific'),
    t('otherlatinamerica'),
    t('global')
  ]
  const fuelTypeOptions = [
    t('gasoline'),
    t('diesellight'),
    t('dieselmedium'),
    t('dieselheavy')
  ]


  return (
    <>
      {convertToList(attributes).map(({ key, value, type }) => {

        if (key === 'EFCategory' || key === 'EFUnit' ){
          const options = key === 'EFCategory' ? categoryOptions : unitOptions();
          return (<>
                <FormControl margin={"normal"} variant={"filled"} key={key}>
                  <InputLabel>{key === 'EFCategory' ?t('sharedTypeCategory') : t('sharedTypeUnit')}</InputLabel>
                  <Select
                      value={value || ''}
                      onChange={ (event) => {
                        updateAttribute(key, event.target.value);
                      }}>
                    {options.map(item => (
                        <MenuItem key={item} value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                  <IconButton className={classes.removeButton} onClick={() => deleteAttribute(key)}>
                    <CloseIcon />
                  </IconButton>
                </FormControl>
              </>
          )
        }
        else if (key === 'region' || key === 'fuelType' ){
          const options = key === 'region' ? regionOptions : fuelTypeOptions;
          return (<>
                <FormControl margin={"normal"} variant={"filled"} key={key}>
                  <InputLabel>{key === 'region' ?t('sharedTypeRegion') : t('sharedTypeFuelType')}</InputLabel>
                  <Select
                      value={value || ''}
                      onChange={ (event) => {
                        updateAttribute(key, event.target.value);
                      }}>
                    {options.map(item => (
                        <MenuItem key={item} value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                  <IconButton className={classes.removeButton} onClick={() => deleteAttribute(key)}>
                    <CloseIcon />
                  </IconButton>
                </FormControl>
              </>
          )
        }
        else if (type === 'boolean') {
          return (
            <Grid container direction="row" justify="space-between">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value}
                    onChange={e => updateAttribute(key, e.target.checked)}
                    />
                }
                label={getAttributeName(key)} />
              <IconButton className={classes.removeButton} onClick={() => deleteAttribute(key)}>
                <CloseIcon />
              </IconButton>
            </Grid>
          );
        }
        else {
          return (
            <FormControl variant="filled" margin="normal" key={key}>
              <>
              <InputLabel>{getAttributeName(key)}</InputLabel>
              <FilledInput
                type={type === 'number' ? 'number' : 'text'}
                value={value || ''}
                onChange={e => updateAttribute(key, e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={() => deleteAttribute(key)}>
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                }
                /></>
            </FormControl>
          );
        }
      })}
      <Button
        size="large"
        variant="outlined"
        color="primary"
        onClick={() => setAddDialogShown(true)}
        startIcon={<AddIcon />}
        className={classes.addButton}>
        {t('sharedAdd')}
      </Button>
      <AddAttributeDialog
          open={addDialogShown}
          onResult={handleAddResult}
          definitions={definitions}
      />
    </>
  );
}

export default EditAttributesView;
