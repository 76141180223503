import React from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { t } from '../../common/LocalizationProvider';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
//import {Search} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";

const ReportNavbar = ({ setOpenDrawer, reportTitle ,history }) => {

    return (
        <AppBar position="fixed" color="inherit">
            <Toolbar>

                <Grid container spacing={2}>

                    <Grid item xs={2}>
                        <IconButton
                            edge="start"
                            onClick={() => history.push('/')}
                            disableRipple>
                            <ArrowBackIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6" noWrap>
                            {t('reportTitle')} {` / ${t(reportTitle)}`}
                        </Typography>
                    </Grid> <Grid item xs={4} >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        style={{
                            position: 'relative', marginLeft: 0,float:"right"


                        }}
                        edge="end"
                        onClick={() => setOpenDrawer(true)}>
                        <MenuIcon />
                    </IconButton>
                </Grid>
                </Grid>


            </Toolbar>
        </AppBar>
    )
}

export default ReportNavbar;
