import { createSlice} from "@reduxjs/toolkit";

const { reducer, actions } = createSlice({
    name: 'language',
    initialState: {language: null},
    reducers: {
        updateLanguage(state, actions) {
            state.language = actions.payload
        },
    },
})

export { actions as languageActions };
export { reducer as languageReducer };