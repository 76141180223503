import React, { useState } from 'react';
import {
    Button,
    FormControl,
    Grid, IconButton,
    InputLabel,
    makeStyles,
    MenuItem,
    Select, Snackbar,
    TextField,
    Typography
} from "@material-ui/core";
import { t } from "../common/LocalizationProvider";
import ReportLayoutPage from "./ReportLayoutPage";
import {DataGrid} from "@material-ui/data-grid";
import {useDispatch, useSelector} from "react-redux";
import {formatDistance, formatDate, formatWeight} from '../common/formatter';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {filteredIdActions} from "../store";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
    gridContainer: {
        margin: theme.spacing(0, -1),
        '& > .MuiGrid-item': {
            padding: theme.spacing(1.5, 1)
        }
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'green',
            },
            '&:hover fieldset': {
                borderColor: 'green',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'green',
            },
        },
    },
    greenDeleteIcon: {
        color: 'green',
    },
}));

const Filter = ({ setItems }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const devices = useSelector(state => Object.values(state.devices.items));
    const filteredId = useSelector(state => state.filtered_Id.filteredId ? state.filtered_Id.filteredId : undefined)

    const [deviceId, setDeviceId] = useState(filteredId);
    const [distance, setDistance] = useState();
    const [weight, setWeight] = useState();

    const [distanceOfThePart, setDistanceOfThePart] = useState([]);
    const [to, setTo] = useState([]);
    const [legs, setLegs] = useState([])

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [msg, setmsg] = useState('');

    const showOnly = false;

    const handleSelectChange = (event) => {
        const selectedDeviceId = event.target.value;
        setDeviceId(selectedDeviceId); // Update local state
        dispatch(filteredIdActions.updateFilteredId(selectedDeviceId)); // Update Redux state
    };

    const handleClick = (mail, json) => {
        const accept = json ? 'application/json' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        handleSubmit(deviceId, mail, { Accept: accept });
    }

    const handleSubmit = async (deviceId, mail, headers) => {
        const item =
            {
                distanceOfThePart: distanceOfThePart,
                to: to
            }
        const query = new URLSearchParams({ deviceId, mail });
        const response = await fetch(`/api/reports/simulator?${query.toString()}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(item),
        });
        if (response.ok) {
            const contentType = response.headers.get('content-type');
            if (contentType) {
                if (contentType === 'application/json') {
                    const res = await response.json();
                    setItems(res);
                    if (res.length === 0){
                        setOpenSnackbar(true)
                        setmsg(t('deviceConfiguration'))
                    }
                } else {
                    window.location.assign(window.URL.createObjectURL(await response.blob()));
                }
            }
        }
        else if (response.status === 400) {
            const errorText = await response.text();
            let spliteText = errorText.split(' ')[0];
            setOpenSnackbar(true)
            setmsg(t(spliteText))
        }
    }

    const handleAddLegs = () => {
        const newLeg ={
            id: legs.length + 1,
            lf: distance,
            distance: weight
        }
        setLegs([...legs, newLeg]);
        setDistanceOfThePart([...distanceOfThePart, distance]);
        setTo([...to, weight])
    }

    const handleDelete = (id) => {
        setLegs(legs.filter((_, index) => index !== id));
        setDistanceOfThePart(distanceOfThePart.filter((_, index) => index !== id));
        setTo(to.filter((_, index) => index !== id));
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <>
        <Grid container spacing={2} className={classes.gridContainer}>
            <Grid item xs={12} sm={6}>
                <FormControl variant="filled" fullWidth>
                    <InputLabel>{t('reportDevice')}</InputLabel>
                    <Select value={deviceId || ''} onChange={handleSelectChange} >
                        {devices.map((device) => (
                            <MenuItem key={device.id} value={device.id}>{device.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>

            <Grid container spacing={2} className={classes.gridContainer}>
                <Grid item xs={12} sm={3}>
                    <FormControl variant="filled" fullWidth>
                        <TextField
                            className={classes.textField}
                            value={distance || ''}
                            onChange={event => setDistance(event.target.value)}
                            label={t('sharedDistance')+': \'Km\''}
                            variant="filled" />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl variant="filled" fullWidth>
                        <TextField
                            className={classes.textField}
                            value={weight || ''}
                            onChange={event => setWeight( event.target.value)}
                            label={t('Weight')+': \'Metric Tons\''}
                            variant="filled" />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                <Button
                    size="large"
                    variant="outlined"
                    color="secondary"
                    startIcon={<AddIcon />}
                    onClick={handleAddLegs}
                >
                    {t('sharedAddLegs')}
                </Button>
                </Grid>
            </Grid>
            {legs.map((leg, index) => (
                <Grid key={index} container spacing={2} className={classes.gridContainer}>
                    <Grid item xs={12} sm={3}>
                            <TextField
                                className={classes.textField}
                                value={leg.lf}
                                variant="outlined" />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                            <TextField
                                className={classes.textField}
                                value={leg.distance }
                                variant="outlined" />
                    </Grid>
                    <IconButton onClick={() => handleDelete(index)}>
                        <DeleteIcon className={classes.greenDeleteIcon}/>
                    </IconButton>
                </Grid>
            ))}
            <Grid container spacing={2} className={classes.gridContainer}>
            <Grid item xs={!showOnly ? 4 : 12} sm={!showOnly ? 2 : 6}>
                <Button
                    onClick={() => handleClick(false, true)}
                    variant='outlined'
                    color='secondary'
                    fullWidth>
                    {t('reportShow')}
                </Button>
            </Grid>
            {!showOnly &&
            <Grid item xs={4} sm={2}>
                <Button
                    onClick={() => handleClick(false, false)}
                    variant='outlined'
                    color='secondary'
                    fullWidth>
                    {t('reportExport')}
                </Button>
            </Grid>}
            {!showOnly &&
            <Grid item xs={4} sm={2}>
                <Button
                    onClick={() => handleClick(true, false)}
                    variant='outlined'
                    color='secondary'
                    fullWidth>
                    <Typography variant="button" noWrap>{t('reportEmail')}</Typography>
                </Button>
            </Grid>}
        </Grid>

            <Snackbar open={openSnackbar} autoHideDuration={12000} onClose={handleCloseSnackbar} >
                <Alert onClose={handleCloseSnackbar} variant="filled" severity="error">
                    {msg}
                </Alert>
            </Snackbar>
        </>
    );
}

const Simulator = () =>{
    const [items, setItems] = useState([]);
    const columns = [
        {
            headerName: t('positionFixTime'),
            field: 'serverTime',
            type: 'dateTime',
            width: 190,
            // width: theme.dimensions.columnWidthDate,
            valueFormatter: ({ value }) => formatDate(value),
        },{
            headerName: t('positionTotalDistance'),
            field: 'totalDistance',
            type: 'number',
            width: 150,
            // width: theme.dimensions.columnWidthNumber,
            valueFormatter: ({ value }) => formatDistance(value, 'km'),
        },{
            headerName: t('positionDistanceLeg'),
            field: 'legDistance',
            type: 'number',
            width: 150,
            // width: theme.dimensions.columnWidthNumber,
            valueFormatter: ({ value }) => formatDistance(value, 'km'),
        },{
            headerName: t('GHG_Emission'),
            field: 'ghgEmissionSingle',
            type: 'number',
            width: 220,
            // width: theme.dimensions.columnWidthDate,
            valueFormatter: ({ value }) => formatWeight(value, 'metric_tons'),
        },{
            headerName: t('blackCarbonGHG'),
            // field: 'serverTime',
            field: 'blackCarbonSingle',
            type: 'number',
            width: 190,
            valueFormatter: ({ value }) => formatWeight(value, 'grams'),
        }
    ];

    return (
        <ReportLayoutPage filter={<Filter setItems={setItems} />}>
            <DataGrid
                rows={items}
                columns={columns}
                // hideFooter
                autoHeight
                getRowId={() => Math.random()}/>
        </ReportLayoutPage>
    );
}

export default Simulator;

