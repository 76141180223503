import React, {useState} from 'react';
import MainToolbar from './MainToolbar';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';

import { t } from './common/LocalizationProvider';
import { useEffectAsync } from './reactHelper';
import {Snackbar} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  box: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const EditItemView = ({ children, endpoint, item, setItem }) => {
  const history = useHistory();
  const classes = useStyles();
  const { id } = useParams();
  const storedToken = localStorage.getItem('tokenThingwings');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msg, setmsg] = useState('');

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffectAsync(async () => {
    if (id) {
      const response = await fetch(`/api/${endpoint}/${id}`);
      if (response.ok) {
        const  res = await response.json()
        setItem(res);
      }
    } else {
      setItem({});
    }

  }, [id]);

  const fetchData = async (url, item, id, storedToken, organ) => {
    try {
      const response = await fetch(url, {
        method: !id ? 'POST' : 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': 'Bearer ' + storedToken,
          'organization': organ,
        },
        body: JSON.stringify(item),
      });
      return response;
    } catch (error) {
      console.error('Fetch error:', error.message);
      throw error;
    }
  };

  const urlFull = () => {
    let url = `/api/${endpoint}`;
    if (id)
      url += `/${id}`;
    else if (endpoint === "users")
      url += `/addnewadmin`;
    else if (endpoint === "devices")
      url += `/adddevice`;
    return url;
  }

  const handleSave = async () => {

    let organ;
    if (id) {
      if (item.organization) {
        organ = item.organization;
        delete item.organization;
      }
    }
    else if (endpoint === "users") {
        if (!item.name || !item.email || !item.password || !item.phone) {
          setOpenSnackbar(true)
          setmsg(t('RequiredFiling'))
          return;
        } else if (item.password.length<6){
          setOpenSnackbar(true)
          setmsg(t('lessSixCharacter'))
          return;
        }
        else {
          const userData = {name: item.name, email: item.email, phone: item.phone, password: item.password, passwordThingwing: item.password};
          item = {organization: item.organization, password: item.password, user: userData}
        }
    }
    if (id && endpoint === "devices"){
      const speedLimit = item.attributes?.speedLimit;
      if (speedLimit !== undefined && (speedLimit === '' || isNaN(Number(speedLimit)) || speedLimit < 1)) {
        setOpenSnackbar(true)
        setmsg(t('speedLimitNotValid'))
        return;
      }
    }

    setLoading(true)
    let response =  await fetchData(urlFull(), item, id, storedToken, organ)
    if (response)
      setLoading(false)

    if (response.ok) {
      history.goBack();
    }
    else if (response.status === 400){
      const errorText = await response.text();
      if(errorText.includes("ERROR: duplicate key"))
        setmsg(t("duplicatedUser"))
      else{
        let spliteText = errorText.split(' ')[0];
        setmsg(t(spliteText))
      }
      setOpenSnackbar(true)
    }
    else {
      setOpenSnackbar(true)
      setmsg(t('UnknownProblem'))
    }
  };

  function habdleDisable() {
    if (loading)
      return true;
    else
      return false;
  }

  const [progress, setProgress] = React.useState(0);

  // React.useEffect(() => {
  //   const timer = setInterval(() => {setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 5));}, 800);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  return (
      <>
        <MainToolbar />
        <Container maxWidth='xs' className={classes.container}>
          {children}
          <div className={classes.loadingContainer}>
            {loading &&
            <Box position="relative" display="inline-flex">
              <CircularProgress color="primary" value={progress} />
              <Box className={classes.box}>
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(progress)}%`}</Typography>
              </Box>
            </Box>
            }
          </div>
          <FormControl fullWidth margin='normal'>
            {(endpoint !== 'devices' || id === undefined || (item != null && item.configured !== "should_delete_C" && item.configured !== "should_delete_N")) && (
            <div className={classes.buttons}>
              <Button type='button' color='primary' variant='outlined' onClick={() => history.goBack()} disabled={habdleDisable()}>
                {t('sharedCancel')}
                {/*make condition here for approval and configure*/}
              </Button>
              {/*{((endpoint === "devices" && attributes.enableAddDevice) || subadminEnabled) && (*/}
                  <Button type='button' color='primary' variant='contained' onClick={handleSave} disabled={habdleDisable()}>
                    {endpoint === "devices" && id !== undefined && item.configured!=="configured" ? (t(item.configured+"Ok") ): (t('sharedSave')) }
                  </Button>
              {/*)}*/}
            </div>
            )}
          </FormControl>
        </Container>
        <Snackbar open={openSnackbar} autoHideDuration={12000} onClose={handleCloseSnackbar} >
          <Alert onClose={handleCloseSnackbar} variant="filled" severity="error">
            {msg}
          </Alert>
        </Snackbar>
      </>
  );
}

export default EditItemView;
