import React, {useEffect, useState} from 'react';
import { isWidthUp, makeStyles, withWidth } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import ContainerDimensions from 'react-container-dimensions';
import DevicesList from './DevicesList';
import MainToolbar from './MainToolbar';
import Map from './map/Map';
import SelectedDeviceMap from './map/SelectedDeviceMap';
import AccuracyMap from './map/AccuracyMap';
import GeofenceMap from './map/GeofenceMap';
import CurrentPositionsMap from './map/CurrentPositionsMap';
import CurrentLocationMap from './map/CurrentLocationMap';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    }
  },
  drawerPaper: {
    background:'#EAEAEA',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      width: 350,
    },
    [theme.breakpoints.down('xs')]: {
      height: 250,
    },
    overflow: 'hidden',
  },
  mapContainer: {
    flexGrow: 1,
  },
  dragger: {
    width: '5px',
    cursor: 'ew-resize',
    padding: '4px 0 0',
    borderTop: '1px solid #ddd',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: '100',
    backgroundColor: '#f4f7f9',
  },
}));

const MainPage = ({ width }) => {
  const classes = useStyles();
  const [state, setState] = useState({isResizing: false, newWidth: {}});

  const handleMousedown = (e) => {
    setState(prevState => ({ ...prevState, isResizing: true }));
  };

  const handleMousemove = (e) => {
    // we don't want to do anything if we aren't resizing.
    if (!state.isResizing) {
      return;
    }

    const offsetRight = e.clientX;
    const minWidth = 100;
    const maxWidth = 600;
    if (offsetRight > minWidth && offsetRight < maxWidth) {
      setState(prevState => ({ ...prevState, newWidth: { width: offsetRight } }));
    }
  };

  const handleMouseup = (e) => {
    setState(prevState => ({...prevState, isResizing: false}));
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMousemove);
    document.addEventListener('mouseup', handleMouseup);

    return () => {
      document.removeEventListener('mousemove', handleMousemove);
      document.removeEventListener('mouseup', handleMouseup);
    }
  },[state.isResizing])

  return (
      <div className={classes.root}>
        <MainToolbar />

        <div className={classes.content}>
          <Drawer
              anchor={isWidthUp('sm', width) ? 'right' : 'bottom'}
              variant='permanent'
              classes={{ paper: classes.drawerPaper }}
              PaperProps={{ style: state.newWidth }}
          >
            <div
                id="dragger"
                onMouseDown={(event) => handleMousedown(event)}
                className={classes.dragger}
            />
            <DevicesList />
          </Drawer>
          <div className={classes.mapContainer}>
            <ContainerDimensions>
              <Map>
                <CurrentLocationMap />
                <GeofenceMap />
                <AccuracyMap />
                <CurrentPositionsMap />
                <SelectedDeviceMap />
              </Map>
            </ContainerDimensions>
          </div>
        </div>
      </div>
  );
}

export default withWidth()(MainPage);
