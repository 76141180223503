import React, { useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';
import { map } from './Map';
import StartsStopsView from "./StartsStopsView";
import {useSelector} from "react-redux";

const StartsStops = React.memo( ({ positions, stops }) => {


    const onMouseEnter = (e) => {e.target.style.cursor = 'pointer';};
    const onMouseLeave = (e) => {e.target.style.cursor = '';};
    const [popup, setPopup] = useState({targetEff: false, pos: null, status: null, deviceName: null});
    const markerRef = useRef([]);
    const markerRef1 = useRef([]);
    const devices = useSelector(state => state.devices.items);

    useEffect(() => {
        const startStopPositions = [positions[0], positions[positions.length-1]];
        if (!startStopPositions || startStopPositions.length === 0) return;

        if (markerRef.current) {
            markerRef.current.forEach(mrk => mrk.marker.remove())
        }
        markerRef.current = []
        startStopPositions.forEach((position, index) => {
            const { longitude, latitude } = position;
            const markerElement = document.createElement('div');
            const iconSource = index === 0 ? 'images/start.png' : 'images/end.png';
            ReactDOM.render(
                <img
                    src={iconSource}
                    alt={index === 0 ? "Start" : "End"}
                    style={{ width: '25px' }}
                />,
                markerElement
            );
            const marker = new mapboxgl.Marker(markerElement)
                .setLngLat([longitude, latitude])
                .addTo(map);

            const status = position.id === positions[0].id ? "Start" : "End";
            markerElement.addEventListener("mouseenter", onMouseEnter);
            markerElement.addEventListener('mouseleave', onMouseLeave);
            markerElement.addEventListener('click', () =>
                setPopup({targetEff: true, pos: position, status: status, deviceName: devices[position.deviceId].name}));

            markerRef.current.push({ marker });
        });
        return () => {
            if (markerRef.current) {
                markerRef.current.forEach(mrk => mrk.marker.remove()); // Remove markers on cleanup
            }
            markerRef.current = []; // Reset markerRef array on component unmount
        };
    }, [devices, positions]);

    useEffect(() => {
        if (!stops || stops.length === 0) return;

        if (markerRef1.current) {
            markerRef1.current.forEach(mrk => mrk.marker.remove())
        }
        markerRef1.current = []
        stops.forEach(stop => {
            const { longitude, latitude } = stop;

            const markerElement = document.createElement('div');
            const iconSource = stop.duration < 120000 ? 'images/parkingOrg.png' : 'images/parkingRed.png';
            ReactDOM.render(
                <img
                    src={iconSource}
                    alt="parking"
                    style={{ width: '20px' }}
                />,
                markerElement
            );
            const marker = new mapboxgl.Marker(markerElement)
                .setLngLat([longitude, latitude])
                .addTo(map);

            markerElement.addEventListener("mouseenter", onMouseEnter);
            markerElement.addEventListener('mouseleave', onMouseLeave);
            markerElement.addEventListener('click', () => setPopup({targetEff: true, pos: stop}));

            markerRef1.current.push({ marker });
        });
        return () => {
            if (markerRef1.current) {
                markerRef1.current.forEach(mrk => mrk.marker.remove()); // Remove markers on cleanup
            }
            markerRef1.current = []; // Reset markerRef array on component unmount
        };
    }, [stops]);

    useEffect(() => {
        if(popup.pos){
            const placeHolder = document.createElement('div');
            ReactDOM.render(
                    <StartsStopsView position={popup.pos} status={popup.status} deviceName={popup.deviceName}/>
                , placeHolder);
            new mapboxgl.Popup({
                offset: 30,
                anchor: 'bottom'
            }).setDOMContent(placeHolder).setLngLat([popup.pos.longitude, popup.pos.latitude]).addTo(map);
        }
    }, [popup]);















    // const id = 'startStop';
    // const clusters = `${id}-clusters`;
    //
    // const createFeature = () => {
    //     return {
    //         deviceId: 30,
    //         name: 'name',
    //         category: 'default',
    //         color: 'red',
    //     }
    // };
    //
    // const onMouseEnter = () => map.getCanvas().style.cursor = 'pointer';
    // const onMouseLeave = () => map.getCanvas().style.cursor = '';
    //
    // const onMarkerClick = useCallback(event => {
    //    console.log("onMarkerClick-------------")
    // }, []);
    //
    // useEffect(() => {
    //         map.addSource(id, {
    //             'type': 'geojson',
    //             'data': {
    //                 type: 'FeatureCollection',
    //                 features: [],
    //             },
    //             'cluster': true,
    //             'clusterMaxZoom': 14,
    //             'clusterRadius': 50,
    //         });
    //         map.addLayer({
    //             'id': id,
    //             'type': 'symbol',
    //             'source': id,
    //             'filter': ['!', ['has', 'point_count']],
    //             'layout': {
    //                 'icon-image': '{category}-{color}',
    //                 'icon-allow-overlap': true,
    //                 'text-field': '{name}',
    //                 'text-allow-overlap': true,
    //                 'text-anchor': 'bottom',
    //                 'text-offset': [0, -2],
    //                 'text-font': ['Roboto Regular'],
    //                 'text-size': 12,
    //             },
    //             'paint': {
    //                 'text-halo-color': 'white',
    //                 'text-halo-width': 1,
    //             },
    //         });
    //         // map.addLayer({
    //         //     'id': clusters,
    //         //     'type': 'symbol',
    //         //     'source': id,
    //         //     'filter': ['has', 'point_count'],
    //         //     'layout': {
    //         //         'icon-image': 'background',
    //         //         'text-field': '{point_count_abbreviated}',
    //         //         'text-font': ['Roboto Regular'],
    //         //         'text-size': 14,
    //         //     },
    //         // });
    //
    //
    //         map.on('mouseenter', id, onMouseEnter);
    //         map.on('mouseleave', id, onMouseLeave);
    //         // map.on('mouseenter', clusters, onMouseEnter);
    //         // map.on('mouseleave', clusters, onMouseLeave);
    //         map.on('click', id, onMarkerClick);
    //         // map.on('click', clusters, onClusterClick);
    //         //
    //         return () => {
    //             Array.from(map.getContainer().getElementsByClassName('mapboxgl-popup')).forEach(el => el.remove());
    //
    //             map.off('mouseenter', id, onMouseEnter);
    //             map.off('mouseleave', id, onMouseLeave);
    //             // map.off('mouseenter', clusters, onMouseEnter);
    //             // map.off('mouseleave', clusters, onMouseLeave);
    //             map.off('click', id, onMarkerClick);
    //             // map.off('click', clusters, onClusterClick);
    //             //
    //             map.removeLayer(id);
    //             // map.removeLayer(clusters);
    //             map.removeSource(id);
    //         };
    //     },
    //     [onMarkerClick]);
    //
    // useEffect(() => {
    //     map.getSource(id).setData({
    //         type: 'FeatureCollection',
    //         features: stops.map(position => ({
    //             type: 'Feature',
    //             geometry: {
    //                 type: 'Point',
    //                 coordinates: [position.longitude, position.latitude],
    //             },
    //             properties: createFeature(),
    //         }))
    //     });
    // }, []);














    return null;
});

export default StartsStops;

