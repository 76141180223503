import React, { useState } from 'react';
import MainToolbar from '../MainToolbar';
import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, makeStyles, IconButton, Switch } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { t } from '../common/LocalizationProvider';
import { useEffectAsync } from '../reactHelper';
import EditCollectionView from '../EditCollectionView';
import {formatBoolean, formatBooleanDesable} from '../common/formatter';
import {useSelector} from "react-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

const useStyles = makeStyles(theme => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  redText:{
    color: "red",
  },
  blueTextUsers:{
    color: "blue",
  },
}));

const UsersView = ({ updateTimestamp, onMenuClick }) => {
  const classes = useStyles();
  const adminEnabled = useSelector(state => state.session.user && state.session.user.administrator);
  const [items, setItems] = useState([]);
  const [expandedUserId, setExpandedUserId] = useState(null);

  const storedValue = localStorage.getItem('tokenThingwings');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [open, setOpen] = useState(false);
  const [validObj, setValidObj] = useState({id: null, validemail: false, validate: false});
  const [message, setMessage] = useState("error");

  const handleRowClick = (userId) => {
    if (expandedUserId === userId) {
      setExpandedUserId(null); // Collapse if already expanded
    } else {
      setExpandedUserId(userId);
    }
  };

  const handleChange = async (id, validate, validemail) => {
    setValidObj({id, validemail, validate})
    setOpen(true)
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSend = async () => {
    if (validObj.validemail) {
      let validateTemp;
      if (validObj.validate) validateTemp = false;
      else validateTemp = true;
      let url = `/api/users/validateSubadmin`;

      let objects = items;
      // objects = objects.map((object) => object.user.id === validObj.id ? {...object, validate: validateTemp} : object);
      let user;
      objects = objects.map((object) => {
        if (object.user.id === validObj.id) {
          user = {...object.user, validate: validateTemp};
          return {...object, user: user};
        }
        return object;
      });

      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': 'Bearer ' + storedValue
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        let res = await response.json();
        // objects = objects.map((item, index) =>item.user.id === validObj.id ? {...item, validate: res.validate} : item,)
        objects = objects.map((object) => {
          if (object.user.id === validObj.id) {
            const updatedUser = {...object.user, validate: res.validate};
            return {...object, user: updatedUser};
          }
          return object;
        });
        setItems(objects);
        setOpenSnackbar(true)
        setMessage("sucsses")
      }
      setOpen(false);
    } else
      setOpen(false);
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  function snackbarColor() {
    if (message === "sucsses")
      return "success"
    else
      return "error"
  }

  function messaging() {
    if (message === "sucsses")
      return t("successndrquest")
    else
      return t("errorsndrquest")
  }

  useEffectAsync(async () => {
    const response = await fetch('/api/users');
    if (response.ok) {
      let res = await response.json()
      //const usersOnly = res.map(resp => resp.user);
      setItems(res);

      // const responseOrg = await fetch('/api/users/getorg?all=true');
      // setOrgs(await responseOrg.json());
    }
  }, [updateTimestamp]);

  const shouldRemove = (item) => {
    return item.user.attributes.hasOwnProperty("shouldRemoved") && item.user.attributes.shouldRemoved;
  };

  return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.columnAction}/>
              <TableCell>{t('sharedName')}</TableCell>
              <TableCell>{t('userEmail')}</TableCell>
              {adminEnabled && <TableCell>{t('userOrganization')}</TableCell>}
              <TableCell>{t('userAdmin')}</TableCell>
              <TableCell>{t('sharedDisabled')}</TableCell>
              {adminEnabled && <TableCell>{t('validate')}</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item) => (
                <React.Fragment key={item.id}>
                  {adminEnabled ? (
                    !item.user.administrator && item.user.subadmin && (
                    <>
                      <TableRow onClick={() => handleRowClick(item.user.id)} key={item.user.id}>
                        <TableCell className={classes.columnAction} padding="none">
                          <IconButton onClick={(event) => onMenuClick(event.currentTarget, item.user.id)}>
                            <MoreVertIcon/>
                          </IconButton>
                        </TableCell>
                        <TableCell className={shouldRemove(item) ? classes.redText : ''}>{item.user.name} </TableCell>
                        <TableCell className={shouldRemove(item) ? classes.redText : ''}>{item.user.email}</TableCell>
                        {adminEnabled && <TableCell
                            className={shouldRemove(item) ? classes.redText : ''}>{item.organization}</TableCell>}
                        <TableCell
                            className={shouldRemove(item) ? classes.redText : ''}>{formatBoolean(item.user, 'administrator')}</TableCell>
                        <TableCell
                            className={shouldRemove(item) ? classes.redText : ''}>{formatBooleanDesable(item.user, 'disabled')}</TableCell>
                        {adminEnabled && (
                            item.user.validemail ? (
                                <>
                                  <TableCell>
                                    <Switch
                                        key={item.user.id}
                                        checked={item.user.validate}
                                        onChange={() => handleChange(item.user.id, item.user.validate, item.user.validemail)}
                                        name="checkedA"
                                        inputProps={{'aria-label': 'secondary checkbox'}}
                                    />

                                  </TableCell>
                                </>
                            ) : (
                                <TableCell className={shouldRemove(item) ? classes.redText : ''}>
                                  {!shouldRemove(item) ? (
                                      <div>
                                        {t('waitinEmailValidate')}
                                      </div>
                                  ) : (
                                      <div>
                                        {t('shouldDeleteSubadmin')}
                                      </div>
                                  )}
                                </TableCell>
                            )
                        )}
                      </TableRow>

                      {items.map((item1) => (
                          (!item1.user.administrator && !item1.user.subadmin && expandedUserId === item1.user.adminid && expandedUserId === item.user.id) && (
                              <TableRow key={item1.user.id}>
                                <TableCell className={classes.columnAction} padding="none">
                                  <IconButton onClick={(event) => onMenuClick(event.currentTarget, item1.user.id)}>
                                    <MoreVertIcon/>
                                  </IconButton>
                                </TableCell>
                                <TableCell className={ classes.blueTextUsers }>{item1.user.name} </TableCell>
                                <TableCell className={classes.blueTextUsers}>{item1.user.email}</TableCell>
                                {adminEnabled && <TableCell
                                    className={classes.blueTextUsers}>{item1.organization}</TableCell>}
                                <TableCell
                                    className={classes.blueTextUsers}>{formatBoolean(item1.user, 'administrator')}</TableCell>
                                <TableCell
                                    className={classes.blueTextUsers}>{formatBooleanDesable(item1.user, 'disabled')}</TableCell>
                                {adminEnabled && (
                                    item1.user.validemail ? (
                                        <>
                                          <TableCell>
                                            <Switch
                                                key={item1.user.id}
                                                checked={item1.user.validate}
                                                onChange={() => handleChange(item1.user.id, item1.user.validate, item1.user.validemail)}
                                                name="checkedA"
                                                inputProps={{'aria-label': 'secondary checkbox'}}
                                            />

                                          </TableCell>
                                        </>
                                    ) : (
                                        <TableCell className={shouldRemove(item1) ? classes.redText : ''}>
                                          {!shouldRemove(item1) ? (
                                              <div>
                                                {t('waitinEmailValidate')}
                                              </div>
                                          ) : (
                                              <div>
                                                {t('shouldDeleteSubadmin')}
                                              </div>
                                          )}
                                        </TableCell>
                                    )
                                )}
                              </TableRow>
                          )
                      ))}
                    </>
                )
                  ) : (
                      <>
                        <TableRow onClick={() => handleRowClick(item.user.id)} key={item.user.id}>
                        <TableCell className={classes.columnAction} padding="none">
                          <IconButton onClick={(event) => onMenuClick(event.currentTarget, item.user.id)}>
                            <MoreVertIcon/>
                          </IconButton>
                        </TableCell>
                        <TableCell className={shouldRemove(item) ? classes.redText : ''}>{item.user.name} </TableCell>
                        <TableCell className={shouldRemove(item) ? classes.redText : ''}>{item.user.email}</TableCell>
                        {adminEnabled && <TableCell
                            className={shouldRemove(item) ? classes.redText : ''}>{item.organization}</TableCell>}
                        <TableCell
                            className={shouldRemove(item) ? classes.redText : ''}>{formatBoolean(item.user, 'administrator')}</TableCell>
                        <TableCell
                            className={shouldRemove(item) ? classes.redText : ''}>{formatBooleanDesable(item.user, 'disabled')}</TableCell>
                        {adminEnabled && (
                            item.user.validemail ? (
                                <>
                                  <TableCell>
                                    <Switch
                                        key={item.user.id}
                                        checked={item.user.validate}
                                        onChange={() => handleChange(item.user.id, item.user.validate, item.user.validemail)}
                                        name="checkedA"
                                        inputProps={{'aria-label': 'secondary checkbox'}}
                                    />

                                  </TableCell>
                                </>
                            ) : (
                                <TableCell className={shouldRemove(item) ? classes.redText : ''}>
                                  {!shouldRemove(item) ? (
                                      <div>
                                        {t('waitinEmailValidate')}
                                      </div>
                                  ) : (
                                      <div>
                                        {t('shouldDeleteSubadmin')}
                                      </div>
                                  )}
                                </TableCell>
                            )
                        )}
                      </TableRow>
                      </>
                  )}
                </React.Fragment>
            ))}
          </TableBody>
        </Table>

        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

          <DialogTitle id="alert-dialog-title">{t("confirmation")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("send message validate")}
            </DialogContentText>
            <Button className={classes.send} type='button' onClick={handleClose}>
              {t("sharedCancel")}
            </Button>
            <Button type='button' color='primary' variant='contained' onClick={handleSend}>
              {t("commandSend")}
            </Button>
          </DialogContent>
        </Dialog>
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} variant="filled" severity={snackbarColor()}>
            {messaging()}
          </Alert>
        </Snackbar>
      </TableContainer>
  );
}
const UsersPage = () => {
  return (
      <>
        <MainToolbar />
        <EditCollectionView content={UsersView} editPath="/user" endpoint="users" />
      </>
  );
}

export default UsersPage;
