import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { t } from './common/LocalizationProvider';
import RemoveDialog from './RemoveDialog';
import ActionDialog from './ActionDialog';


const EditCollectionViewDevice = ({ content, editPath, endpoint }) => {
    const history = useHistory();

    const [selectedId, setSelectedId] = useState(null);
    const [seleId, setSeleId] = useState(null);
    const [selectedAnchorEl, setSelectedAnchorEl] = useState(null);
    const [removeDialogShown, setRemoveDialogShown] = useState(false);
    const [updateTimestamp, setUpdateTimestamp] = useState(Date.now());
    const [actionDialogShown, setActionDialogShown] = useState(false);

    const menuShow = (anchorId, itemId) => {
        setSelectedAnchorEl(anchorId);
        setSelectedId(itemId);

    }
    const goTo = (itemId) => {
        setSeleId(itemId)
    }
    const menuHide = () => {
        setSelectedAnchorEl(null);
    }
    const handleEdit = () => {
        history.push(`${editPath}/${selectedId}`);
        menuHide();
    }
    const handleRemove = () => {
        setRemoveDialogShown(true);
        menuHide();
    }
    const handleAction = () => {
        setActionDialogShown(true)
        menuHide();
    }
    const handleClose = () => {
        setActionDialogShown(false)
        setUpdateTimestamp(Date.now());
    }
    const handleRemoveResult = () => {
        setRemoveDialogShown(false);
        setUpdateTimestamp(Date.now());
    }

    const Content = content;

    return (
        <>
            <Content updateTimestamp={updateTimestamp} thisOne={seleId} goTo={goTo} onMenuClick={menuShow}/>
            <Menu open={!!selectedAnchorEl} anchorEl={selectedAnchorEl} onClose={menuHide}>
                <MenuItem onClick={handleEdit}>{t('sharedEdit')}</MenuItem>
                <MenuItem onClick={handleRemove}>{t('sharedRemove')}</MenuItem>
                <MenuItem onClick={handleAction}>{t('moreactions')}</MenuItem>
            </Menu>
            <RemoveDialog open={removeDialogShown} endpoint={endpoint} itemId={selectedId}
                          onResult={handleRemoveResult}/>
            <ActionDialog open={actionDialogShown} itemId={selectedId} handleClose={handleClose} history={history}/>
        </>
    );
}

export default EditCollectionViewDevice;
